import React from "react";

const MessageScreen = (props) => {
  return (
    <React.Fragment>
      <div>{props.child}</div>
    </React.Fragment>
  );
};

export default MessageScreen;
