import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import global_en from "./translations/en/global.json";
import global_es from "./translations/es/global.json";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "./themes/Theme";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

i18next.init({
  lng: "es",
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});

root.render(
  <StrictMode>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <ThemeProvider theme={Theme}>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </I18nextProvider>
  </StrictMode>
);

reportWebVitals();
