import { Amplify, Auth } from "aws-amplify";

export default class Cognito {
  constructor() {
    this.PREFIX = "COG";
    this.SMS_MFA = "SMS_MFA";
    this.DEVICE_MOBILE = "MOBILE";
    this.AUTHENTICATION_PIN = "";
    this.AUTHENTICATION_PASS = "PASSWORD";
  }

  configSignIn() {
    Amplify.configure({
      Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        authenticationFlowType: process.env.REACT_APP_COGNITO_AUTH_FLOW_TYPE,
      },
    });
  }

  configRegister(props) {
    const { username, password } = props;

    const pin = /^\d{4}$/;
    const authentication_type = pin.test(password)
      ? this.AUTHENTICATION_PIN
      : this.AUTHENTICATION_PASS;

    Amplify.configure({
      Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        authenticationFlowType: process.env.REACT_APP_COGNITO_USER_FLOW_TYPE,
      },
      clientMetadata: {
        Name: username,
        Country: process.env.REACT_APP_COUNTRY,
        password: password,
        device_type: this.DEVICE_MOBILE,
        authentication_type: authentication_type,
        Environment: process.env.REACT_APP_ENVIRONMENT_COGNITO,
      },
    });
  }

  async signIn(props) {
    const { username, password } = props;
    return await Auth.signIn(username, this.PREFIX + password);
  }

  async confirmSignIn(props) {
    const { user, otp } = props;
    return await Auth.confirmSignIn(user, otp, this.SMS_MFA);
  }
}
