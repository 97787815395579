import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import "../components.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Logo from "../../assets/logoTigo/TigoMoneyHorizontal.png";
import returning from "../../assets/loaders/returning.svg";
import { useTranslation } from "react-i18next";
import Buttons from "../Buttons";
import { isValidURL } from "./validations";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "449px",
  width: "377px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
};

const style2 = {
  p: 4,
};

export default function Alert({
  withButton = false,
  icon = "error",
  message = {
    tittle: "titulo",
    message: "mensaje",
  },
  method,
  isBlocking = true,
  hasSubmit,
  callback,
}) {
  const [t] = useTranslation("global");
  const [open, setOpen] = React.useState(!withButton);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const backdropProps = isBlocking ? { onClick: null } : {};
  const _style = {
    cancel: {
      name: "Cancel",
      props: {},
    },
    submit: {
      name: t("Alerts.Understood"),
      props: { sm: 12 },
    },
  };

  const iconmessage = () => {
    if (icon === "loading") {
      return (
        <div className="loader">
          <div className="round"></div>
        </div>
      );
    } else {
      return (
        <div>
          {icon === "success" ? (
            <CheckCircleIcon style={{ fontSize: "80px", color: "#66BC6B" }} />
          ) : (
            <CancelIcon style={{ fontSize: "80px", color: "#D95A3A" }} />
          )}
        </div>
      );
    }
  };

  return (
    <div>
      {withButton && (
        <Button
          style={{
            backgroundColor: "#363853",
            color: "#FFFFFF",
            marginLeft: "10px",
          }}
          onClick={handleOpen}
        >
          {t("Alerts.Next")}
        </Button>
      )}
      <Modal open={open} onClose={handleClose} BackdropProps={backdropProps}>
        <React.Fragment>
          <Grid container sx={style} direction="row" justifyContent="center">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                background: "#FABE00",
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
              }}
            >
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <img
                  style={{ width: "120px", height: "30px", padding: "10px" }}
                  src={Logo}
                  alt=""
                />
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
            <Grid sx={style2} item xs={12} md={12}>
              <Typography id="tittle" sx={{ textAlign: "center" }}>
                &nbsp;
                {message.tittle}&nbsp;
              </Typography>
            </Grid>
            {iconmessage()}
            <Grid sx={style2} item xs={12} md={12}>
              <Typography id="message" sx={{ textAlign: "center" }}>
                &nbsp;
                {message.message}&nbsp;
              </Typography>
            </Grid>
            {!hasSubmit && isValidURL(callback) && (
              <Grid sx={style2} item xs={12} md={12}>
                <Typography id="redirect" sx={{ textAlign: "center" }}>
                  <img
                    src={returning}
                    alt=""
                    style={{ height: "20px", width: "20px" }}
                  />
                  &nbsp;&nbsp;
                  {t("Alerts.redirect")}
                  &nbsp;&nbsp;
                  <img
                    src={returning}
                    alt=""
                    style={{ height: "20px", width: "20px" }}
                  />
                </Typography>
              </Grid>
            )}
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                {icon !== "loading" && (
                  <Buttons
                    style={_style}
                    data-testid="btnModal"
                    cancel={() => {}}
                    hasSubmit={hasSubmit}
                    submit={(e) => {
                      if (method) {
                        method(e);
                        handleClose();
                      } else {
                        handleClose();
                      }
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      </Modal>
    </div>
  );
}
