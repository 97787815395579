/**
 * The function `isValidURL` checks if a given string is a valid URL.
 *
 * Args:
 *   urlString: The `urlString` parameter is a string that represents a URL.
 *
 * Returns:
 *   The function `isValidURL` returns a boolean value indicating whether the given `urlString` is a
 * valid URL or not.
 */
export const isValidURL = (urlString) => {
  try {
    new URL(urlString);
    return true;
  } catch (_) {
    return false;
  }
};
