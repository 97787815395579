import { React, useEffect, useState, useMemo, Fragment } from "react";
import OnBoardingLayout from "../components/LayerTigo";
import GenericComponent from "../components/GenericComponent";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Cognito from "../components/utils/cognito";
import Alert from "../components/utils/Alert";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
import { isValidURL } from "../components/utils/validations";

export default function PaymentButtonProcess() {
  const [t] = useTranslation("global");
  const cognito = new Cognito();
  const USER_NOT_FOUND = "UserNotFoundException";

  /**
   * The function `useQuery` returns a memoized instance of `URLSearchParams` based on the current
   * search query parameters in the URL.
   *
   * Returns:
   *   a memoized instance of the URLSearchParams object, which is created using the search parameter
   * from the useLocation() hook.
   */
  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  let preRequestId = query.get("id") || "";
  preRequestId = decodeURIComponent(preRequestId).replace(/ /g, "+");

  const [callback, setCallback] = useState("");
  let originInvoke = query.get("invoke") || "";
  const URLOrigin = decodeURIComponent(originInvoke);

  let userResend;
  let preRequest = {};
  const [data, setData] = useState({});

  const [icon, setIcon] = useState("");
  const [title, setTitle] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(true);

  const messageAlert = {
    tittle: t(title),
    message: t(message),
  };

  /* The `useEffect` hook is used to perform side effects in a React component. In this case, the
  effect is triggered when the `callback` variable changes. */
  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (isValidURL(callback)) window.location.href = callback;
    }, 2400);

    return () => clearTimeout(timeoutID);
  }, [callback]);

  const handlePaymentButton = async (data, callback) => {
    setMessage("Alerts.message_verify_pay.message");
    setTitle("Alerts.message_verify_pay.title");
    setIcon("loading");
    setOpen(true);

    const deviceKey = decodeMtsToken(data.token).DEVICE_KEY;

    const body = {
      pre_request: preRequestId,
      debit_party: deviceKey,
    };

    const headers = {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_DOMAIN_APIKEY,
      Authorization: data.token,
      Accept: "application/json",
    };

    return await axios
      .post(
        `${process.env.REACT_APP_DOMAIN}/transactions/payment`,
        { ...body },
        { headers }
      )
      .then((response) => {
        setMessage("Alerts.message_success.message");
        setTitle("Alerts.message_success.title");
        setIcon("success");
        setSubmitSuccess(false);
        setCallback(callback.success || URLOrigin);

        return response;
      })
      .catch((err) => {
        if (err.response.data.message === "exceeded") {
          setMessage("Alerts.message_attempts_exceeded.message");
          setTitle("Alerts.message_attempts_exceeded.title");
          setIcon("error");
          setSubmitSuccess(false);
          setCallback(callback.error || URLOrigin);
        } else {
          setMessage("Alerts.message_problem.message");
          setTitle("Alerts.message_problem.title");
          setIcon("error");
          setSubmitSuccess(true);
        }

        return err;
      });
  };

  const getPreRequestInfo = async () => {
    setMessage("Alerts.message_verify.message");
    setTitle("Alerts.message_verify.title");
    setIcon("loading");
    setOpen(true);

    if (!preRequestId) {
      setMessage("Alerts.message_login.message");
      setTitle("Alerts.message_login.title");
      setIcon("error");
      setOpen(true);
      setSubmitSuccess(false);
      setCallback(URLOrigin);

      return null;
    }

    const headers = {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_DOMAIN_APIKEY,
      Accept: "application/json",
    };

    return await axios
      .get(
        `${process.env.REACT_APP_DOMAIN}/transactions/payment/pre_request?pre_request=${preRequestId}`,
        { headers }
      )
      .then((response) => {
        if (response.data.status === "pending payment") {
          setOpen(false);

          return response.data;
        } else {
          setMessage("Alerts.message_success_prerequest.message");
          setTitle("Alerts.message_success_prerequest.title");
          setIcon("error");
          setOpen(true);
          setSubmitSuccess(false);
          setCallback(URLOrigin);
        }
      })
      .catch((err) => {
        setMessage("Alerts.message_login.message");
        setTitle("Alerts.message_login.title");
        setIcon("error");
        setOpen(true);
        setSubmitSuccess(false);
        setCallback(URLOrigin);

        return err;
      });
  };

  const handleValidateLogin = async (codCountry, number, pin) => {
    setMessage("Alerts.message_verify.message");
    setTitle("Alerts.message_verify.title");
    setIcon("loading");
    setOpen(true);

    await singInFlow(codCountry, number, pin);
  };

  const singInFlow = async (code, number, pin) => {
    cognito.configSignIn();

    try {
      await userSignIn(code, number, pin);
    } catch (error) {
      if (error?.code === USER_NOT_FOUND) {
        await userRegister(code, number, pin);
      } else {
        setMessage("Alerts.message_login.message");
        setTitle("Alerts.message_login.title");
        setIcon("error");
        setOpen(true);
      }
    }
  };

  const userSignIn = async (code, number, pin) => {
    const responseCognito = await cognito.signIn({
      username: code + number,
      password: pin,
    });

    const data = {
      country: code,
      userName: number,
      pin: pin,
      userLogin: responseCognito,
    };

    let nextStepInfo = [];
    nextStepInfo = { data };
    nextStepInfo["type"] = "otpCognito";
    nextStepInfo["ValidateOTP"] = handleValidateOTP;
    nextStepInfo["resendCode"] = resendCodeOTP;

    setData(nextStepInfo);
    setOpen(false);
  };

  const userRegister = async (code, number, pin) => {
    cognito.configRegister({ username: number, password: pin });

    try {
      await userSignIn(code, number, pin);
      await singInFlow(code, number, pin);
    } catch (_) {
      setMessage("Alerts.message_login.message");
      setTitle("Alerts.message_login.title");
      setIcon("error");
      setOpen(true);
    }
  };

  const handleValidateOTP = async (OtpCode, dataLogin) => {
    setMessage("Alerts.message_verify.message");
    setTitle("Alerts.message_verify.title");
    setIcon("loading");
    setOpen(true);

    try {
      const userCognito = userResend || dataLogin.userLogin;

      const responseCognito = await cognito.confirmSignIn({
        user: userCognito,
        otp: OtpCode,
      });

      const data = {
        token: responseCognito["signInUserSession"]["idToken"]["jwtToken"],
      };

      let newData = [];
      newData = { ...preRequest, data };
      newData["type"] = "confirmPayment";
      newData["payment"] = handlePaymentButton;

      setData(newData);
      setOpen(false);
    } catch (_) {
      setMessage("Alerts.message_error_verify_otp.message");
      setTitle("Alerts.message_error_verify_otp.title");
      setIcon("error");
      setOpen(true);
    }
  };

  const resendCodeOTP = async (data) => {
    try {
      userResend = await cognito.signIn({
        username: data.country + data.userName,
        password: data.pin,
      });

      return true;
    } catch (_) {
      setMessage("Alerts.message_login.message");
      setTitle("Alerts.message_login.title");
      setIcon("error");
      setOpen(true);

      return false;
    }
  };

  const decodeMtsToken = (IdToken) => {
    const token = IdToken ? jwt_decode(IdToken) : undefined;
    return token ? JSON.parse(window.atob(token?.mtsToken)) : undefined;
  };

  useEffect(() => {
    const fetchData = async () => {
      preRequest = await getPreRequestInfo();
    };

    fetchData();
    setData({
      type: "formSignIn",
      validateLogin: handleValidateLogin,
    });
  }, []);

  return (
    <Fragment>
      {open ? (
        <Alert
          isBlocking={true}
          icon={icon}
          message={messageAlert}
          method={() => {
            setOpen(false);
          }}
          hasSubmit={submitSuccess}
          callback={callback}
        ></Alert>
      ) : (
        <OnBoardingLayout screen={<GenericComponent {...data} />} />
      )}
    </Fragment>
  );
}
