import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  InputAdornment,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  Select,
  Typography,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { dataPerCountry } from "./utils/dataPerCountry";

const FormSingIn = (props) => {
  const [t] = useTranslation("global");

  const [code, setCode] = useState("");
  const [codeDisabled, setCodeDisabled] = useState(false);

  const [number, setNumber] = useState("");
  const [pin, setPin] = useState({
    password: "",
    showPassword: false,
  });

  const [markNumber, setMarkNumber] = useState("");
  const [formatNumber, setFormatNumber] = useState({});

  useEffect(() => {
    if (localStorage.getItem("timer") !== null)
      localStorage.removeItem("timer");

    let domain = window.location.hostname;
    if (!domain.includes("localhost")) domain = domain.split(".")[3];
    domain = domain.toUpperCase();

    setDataPerCountry(domain);
  }, []);

  const setDataPerCountry = (props) => {
    dataPerCountry.forEach((item) => {
      if (props === item.country) setCodeDisabled(true);

      if (props === item.country || props === item.code) {
        setCode(item.code);
        setMarkNumber(item.markNumber);
        setFormatNumber(item.formatNumber);
      }
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    props.validateLogin(code, number, pin.password);
  };

  const handleChange = (prop) => (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    if (name === "number") setNumber(value);
    if (name === "codCountry") setDataPerCountry(value);
    if (name === "pin") setPin({ ...pin, [prop]: value });
  };

  const handleClickShowPassword = (event) => {
    event.preventDefault();

    setPin({
      ...pin,
      showPassword: !pin.showPassword,
    });
  };

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: "#fff",
        borderRadius: "16px",
        fontFamily: "Readex Pro",
      }}
      padding={1}
    >
      <Stack justifyContent="center" alignItems="center" mt={4} mb={4}>
        <h2
          style={{
            fontFamily: "Readex Pro",
            fontSize: "28px",
            fontWeight: "600",
            lineHeight: "34px",
            letterSpacing: "0px",
            color: "#343C46",
          }}
        >
          {t(`Login.Title`)}
        </h2>
      </Stack>
      <form
        onSubmit={(event) => {
          handleLogin(event);
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Stack sx={{ width: "40%" }}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="country-code-select-label">
                {t("Login.CountryCode")}
              </InputLabel>
              <Select
                labelId="country-code-select-label"
                id="country-code"
                inputProps={{ "data-testid": "select-country" }}
                label={t("Login.CountryCode")}
                name="codCountry"
                onChange={handleChange()}
                sx={{ borderRadius: "16px", width: "100%" }}
                defaultValue=""
                value={code}
                disabled={codeDisabled}
              >
                {dataPerCountry.map((item, index) => (
                  <MenuItem value={item.code} key={item.code}>
                    <img
                      src={item.flag}
                      alt={item.country}
                      style={{ height: "20px", width: "20px" }}
                    />
                    &nbsp;
                    {item.country + item.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack sx={{ width: "60%" }}>
            <TextField
              required
              name="number"
              label={t("Login.MobileNumber")}
              onChange={handleChange()}
              inputProps={{ ...formatNumber, "data-testid": "phonenumber" }}
              autoComplete="username"
              placeholder={markNumber}
              InputProps={{
                sx: {
                  borderRadius: "16px",
                  fontSize: "Readex Pro",
                  width: "100%",
                },
              }}
            />
          </Stack>
        </Stack>
        <Stack sx={{ width: "100%", marginTop: "40px" }} spacing={4}>
          <TextField
            required
            label={t("Login.Dual")}
            name="pin"
            variant="outlined"
            inputProps={{ "data-testid": "pin-number" }}
            type={pin.showPassword ? "text" : "password"}
            onChange={handleChange("password")}
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    data-testid="ShowPass"
                    onClick={handleClickShowPassword}
                  >
                    {pin.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                borderRadius: "16px",
                fontSize: "Readex Pro",
                width: "100%",
              },
            }}
          />
        </Stack>
        <Stack mt={4} mb={4} justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            type="submit"
            data-testid="enter-password"
            sx={{
              width: "100%",
              height: "48px",
              background: "#363853",
              color: "white",
              fontFamily: "Readex Pro",
              margin: "10px 0px 20px 0px",
              borderRadius: "16px",
            }}
          >
            {t("Login.Enter")}
          </Button>
        </Stack>
        <Stack mt={4}>
          <Typography variant="legend" paragraph>
            {t("Login.NewPassword")}
          </Typography>
        </Stack>
      </form>
    </Stack>
  );
};

export default FormSingIn;
