const libdenomination = {
  usd: "US$",
  eur: "€",
  jpy: "¥",
  gbp: "£",
  mxn: "$",
};

const local = {
  BOB: "es-BO",
  USD: "en-US",
  MXN: "es-MX",
  HNL: "es-HN",
  PAN: "es-PA",
  PYG: "es-PY",
  SLV: "es-SV",
};

const truncate = (num) => {
  return num.length === 0
    ? num + ".00"
    : num.length === 2
    ? num + "0"
    : num.substring(0, 3);
};
const format = (num, detail) => {
  if (num !== "") {
    let number = [
      num.slice(0, -(detail.decimal ? detail.decimal : 2)),
      num.slice(-(detail.decimal ? detail.decimal : 2)),
    ];
    if (number[0] === "") {
      return "0." + number[1];
    }

    let thousands = number[0]
      .split("")
      .reverse()
      .join("")
      .match(/.{1,3}/g)
      .join(",");
    let decimals = "." + number[1];
    num = thousands.split("").reverse().join("") + truncate(decimals);
  }
  return num;
};

const decimal = (num, decimal) => {
  let number = num.replace(/\D/g, "");
  return number.slice(0, -decimal) + "." + number.slice(-decimal);
};
const money = (num, detail) => {
  const symbol = libdenomination[[detail.denomination]];
  return !num.includes(symbol) && num !== "" ? symbol + num : num;
};

const percentage = (num, detail) => {
  num = num.replace("%", "");
  return num + "%";
};
const other = (num, detail) => {
  return detail.symbol ? num + detail.symbol : num;
};
const ccard = (num, detail) => {
  let x = num.replace(/\D/g, "").match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
  x = x
    .slice(1)
    .filter((n) => n !== "")
    .join(" ");
  return x;
};

function currencyFormatter(number, currency) {
  return new Intl.NumberFormat(local[currency.toUpperCase()], {
    style: "currency",
    currency: currency,
  }).format(number);
}

export {
  format,
  decimal,
  money,
  percentage,
  other,
  ccard,
  truncate,
  currencyFormatter,
};
